import http from './http';

import type { PageQuery, GetByID } from './http_types';

import type { NewsCoupon } from './model.type';

const base_path = '/news_coupon';

const get = (q : GetByID) => {
    return http.get(`${base_path}/get?${http.GetByIDToUrlParams(q)}`)
}

const del = (q : GetByID) => {
    return http.post(`${base_path}/delete?${http.GetByIDToUrlParams(q)}`)
}
const create = (v: NewsCoupon) => {
    return http.post(`${base_path}/create`, v)
}

const update = (v: NewsCoupon, q: GetByID) => {
    return http.post(`${base_path}/update?${http.GetByIDToUrlParams(q)}`, v)
}

const list = (q: PageQuery) => {
    return http.get(`${base_path}/list?${http.pageToUrlParams(q)}`)
}

export default {
    get,
    del,
    create,
    update,
    list,
};
